import React from 'react';
import RequireLogin from '../components/Auth/RequireLogin';
import { Router } from '@reach/router';
import NotFound from '@components/Routing/NotFound';
import ViewClubInvoiceReport from '@presenters/web/pages/ViewClubInvoiceReport';
import { FEATURE_INVOICE, isEnabled } from '@utils/features';

const ViewClubInvoiceReportPage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  return (
    <RequireLogin>
      <Router basepath={`/${languagePrefix}`}>
        <ViewClubInvoiceReport
          path="/view-club-invoice-report/:clubId"
          clubId="is set from path"
        />
      </Router>
    </RequireLogin>
  );
};

export default ViewClubInvoiceReportPage;
